import { useState, useEffect } from "react";
import { storage } from "../firebase";
import { getDownloadURL, ref, listAll, getMetadata } from "firebase/storage";
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Backdrop,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import formatBytes from "../formatBytes";
import QRCodeDialog from "../components/QRCodeDialog";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const FileList = ({ fileList, steps }) => {
  const [quCodeDialogOpen, setQrCodeDialogOpen] = useState(false);
  const [fileLink, setFileLink] = useState("");
  return (
    <>
      <QRCodeDialog
        open={quCodeDialogOpen}
        setOpen={setQrCodeDialogOpen}
        link={fileLink}
      />
      <List>
        {fileList.map((file, index) => (
          <Box key={file.url}>
            <ListItem
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    setFileLink(file.url);
                    setQrCodeDialogOpen(true);
                  }}
                >
                  <QrCode2Icon
                    sx={{
                      fontSize: "1.8rem",
                    }}
                  />
                </IconButton>
              }
            >
              <ListItemButton
                tabIndex={
                  steps &&
                  ((steps === "even" && index * 2 + 1) ||
                    (steps === "odd" && (index + 1) * 2))
                }
                component="a"
                href={file.url}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {file.name.substring(37)}
                    </Typography>
                  }
                  secondary={
                    <span
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography variant="body2" component="span">
                        {formatBytes(file.size)}
                      </Typography>
                      <Typography variant="body2" component="span">
                        {moment(file.timeCreated).format(
                          "Do MMMM, YYYY [at] h:mm:ss A"
                        )}{" "}
                        ({moment(file.timeCreated).fromNow()})
                      </Typography>
                    </span>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Box>
        ))}
      </List>
    </>
  );
};

const Uploads = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMDUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const shortURLBase = "https://download.joon.com.np";

  const getFileList = async () => {
    const storageRef = ref(storage, "/test_uploads");

    const fileList = [];
    const res = await listAll(storageRef);

    await Promise.all(
      res.items.map(async (itemRef) => {
        const metadata = await getMetadata(itemRef);
        let url;
        if (metadata.customMetadata?.shortURL) {
          url = shortURLBase + metadata.customMetadata.shortURL;
        } else {
          url = await getDownloadURL(itemRef);
        }

        fileList.push({
          url,
          ...metadata,
        });
      })
    );

    setFileList(fileList.sort((a, b) => +b.generation - +a.generation));
    setLoading(false);
  };

  useEffect(() => {
    getFileList();
  }, []);

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Card
          sx={{
            margin: "auto",
            position: "relative",
          }}
        >
          <CardContent
            sx={{
              pb: "0 !important",
              px: "0 !important",
            }}
          >
            <Typography
              sx={{
                mx: 2,
              }}
              variant="h5"
              component="h1"
            >
              Uploaded Files
            </Typography>

            <Box
              sx={{
                py: 1,
                position: "relative",
                minHeight: "10rem",
              }}
            >
              <Backdrop
                open={loading}
                sx={{
                  backgroundColor: "#ffffffaa",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  dsiplay: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />

                <Typography
                  variant="body2"
                  sx={{
                    mt: 2,
                    textAlign: "center",
                  }}
                >
                  Loading
                </Typography>
              </Backdrop>
              {fileList.length === 0 && !loading && (
                <Typography
                  variant="body1"
                  component="h2"
                  sx={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    pb: 3,
                  }}
                >
                  No files uploaded.
                </Typography>
              )}
              {isMDUp ? (
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <FileList
                      fileList={fileList.filter(
                        (element, index) => index % 2 === 0
                      )}
                      steps="even"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FileList
                      fileList={fileList.filter(
                        (element, index) => index % 2 === 1
                      )}
                      steps="odd"
                    />
                  </Grid>
                </Grid>
              ) : (
                <FileList fileList={fileList} />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Uploads;
