import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#4287f5",
      light: "#f5f9ff",
      light2: "#e6edf7",
      background: "rgb(231, 235, 240)",
    },
    secondary: {
      main: "#19857b",
    },
    purple: {
      main: "#BF40BF",
    },
    error: {
      main: red.A400,
    },
    success: {
      main: "#4caf50",
    },
  },
});

export default theme;
