import NotesList from "../components/NotesList";
import UploadFile from "../components/UploadFile";
import { Grid } from "@mui/material";
const NoteAndFileUpload = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <UploadFile />
      </Grid>
      <Grid item xs={12} md={6}>
        <NotesList />
      </Grid>
    </Grid>
  );
};

export default NoteAndFileUpload;
