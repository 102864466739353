import { useState, useEffect, useRef } from "react";
import {
  Divider,
  Card,
  CardContent,
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import { storage } from "../firebase";
import { ref, listAll, uploadString, getMetadata } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";

const NotesList = () => {
  const location = useLocation();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noteAddLoading, setNoteAddLoading] = useState(false);
  const formRef = useRef(null);
  const noteInputRef = useRef(null);
  const listRef = useRef(null);
  const storageRef = ref(storage, `/notes`);

  const addNote = async (e) => {
    e.preventDefault();
    setNoteAddLoading(true);
    const itemRef = ref(
      storage,
      `/notes/${uuidv4()} ${encodeURIComponent(noteInputRef.current.value)}`
    );
    formRef.current.reset();
    noteInputRef.current.focus();
    listRef.current.scrollTo({ top: 0, behavior: "smooth" });
    await uploadString(itemRef, "");
    getNoteList();
  };

  const getNoteList = async () => {
    const noteList = [];
    const res = await listAll(storageRef);
    await Promise.all(
      res.items.map(async (itemRef) => {
        const { name, generation } = await getMetadata(itemRef);
        noteList.push({
          name: decodeURIComponent(name),
          generation: generation,
        });
      })
    );
    setNotes(noteList.sort((a, b) => +b.generation - +a.generation));
    setLoading(false);
    setNoteAddLoading(false);
  };

  useEffect(() => {
    getNoteList();
  }, [location]);

  return (
    <Container>
      <Card>
        <CardContent
          sx={{
            pb: "0 !important",
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{
              mb: 2,
            }}
          >
            Notes
          </Typography>
          <form
            ref={formRef}
            onSubmit={addNote}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              label="Enter the note here"
              multiline
              autoFocus
              inputRef={noteInputRef}
              required
              sx={{
                flexGrow: 1,
                mx: 1,
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  addNote(e);
                }
              }}
            />

            <LoadingButton
              type="submit"
              endIcon={<AddIcon />}
              loading={noteAddLoading}
              variant="contained"
              sx={{
                m: 1,
              }}
            >
              Add
            </LoadingButton>
          </form>
          <Box
            sx={{
              py: 1,
              height: "64vh",
              position: "relative",
              minHeight: "10rem",
            }}
          >
            <Backdrop
              open={loading}
              sx={{
                backgroundColor: "#ffffffaa",
                position: "absolute",
                height: "100%",
                width: "100%",
                dsiplay: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
              <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  textAlign: "center",
                }}
              >
                Loading
              </Typography>
            </Backdrop>

            {notes.length === 0 && !loading && (
              <Typography
                variant="body1"
                component="h2"
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pb: 3,
                }}
              >
                No notes yet.
              </Typography>
            )}
            <List
              ref={listRef}
              sx={{
                p: 0,
                height: "100%",
                overflow: "auto",
                ["-webkit-overflow-scrolling"]: "touch",
              }}
            >
              {notes.map((note, index) => (
                <Box key={note.generation}>
                  {index !== 0 && <Divider component="li" />}
                  <ListItem
                    sx={{
                      px: 1,
                    }}
                    alignItems="flex-start"
                  >
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            display: "inline",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {note.name.substring(37)}
                          {/* <button onClick={() => deleteNote(note.id)}>Delete</button> */}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Box>
              ))}
            </List>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default NotesList;
