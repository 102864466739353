import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import QRcode from "qrcode";
import { useEffect, useState } from "react";

export default function QRCodeDialog({ open, setOpen, link }) {
  const handleClose = () => {
    setOpen(false);
  };

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (open && link) {
      QRcode.toDataURL(link, function (err, url) {
        if (err) throw err;
        setImageSrc(url);
      });
    }
  }, [link]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="alert-dialog-title">{"QR Code"}</DialogTitle>
      <DialogContent
        sx={{
          py: 0,
        }}
      >
        <DialogContentText id="alert-dialog-description">
          <img
            style={{
              width: "100%",
            }}
            src={imageSrc}
            alt="QRCode"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}
