import { useState, useCallback } from "react";
import { storage } from "../firebase";
import {
  ref,
  uploadBytesResumable,
  updateMetadata,
  getDownloadURL,
} from "firebase/storage";
import {
  Container,
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  List,
  Divider,
  ListItem,
  ListItemText,
  LinearProgress,
  ListItemButton,
  IconButton,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import formatBytes from "../formatBytes";
import QRCodeDialog from "../components/QRCodeDialog";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const UploadFile = () => {
  const [progresses, setProgresses] = useState([]);

  const [quCodeDialogOpen, setQrCodeDialogOpen] = useState(false);
  const [fileLink, setFileLink] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uploadFiles(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadFiles = (file) => {
    if (!file) return;
    const filename = `${uuidv4()} ${file.name}`;

    setProgresses((progresses) => [
      {
        filename,
        percentage: "0%",
        bytesTransferred: formatBytes(0),
        totalBytes: formatBytes(file.size),
      },
      ...progresses,
    ]);

    const itemRef = ref(storage, `/test_uploads/${filename}`);
    const uploadTask = uploadBytesResumable(itemRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percentage = `${Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )}%`;
        setProgresses((progresses) =>
          progresses.map((progress) => {
            if (progress.filename === filename) {
              return {
                ...progress,
                percentage,
                bytesTransferred: formatBytes(snapshot.bytesTransferred),
                totalBytes: formatBytes(snapshot.totalBytes),
              };
            } else {
              return progress;
            }
          })
        );
      },
      (error) => {
        console.error(error);
      },
      async () => {
        setProgresses((progresses) =>
          progresses.map((progress) => {
            if (progress.filename === filename) {
              return {
                ...progress,
                percentage: "Generating Link...",
              };
            } else {
              return progress;
            }
          })
        );

        if (file.type.includes("video")) {
          updateMetadata(itemRef, {
            contentDisposition: "inline; filename=" + file.name,
          });
        } else {
          updateMetadata(itemRef, {
            contentDisposition: "attachment; filename=" + file.name,
          });
        }

        const url = await getDownloadURL(itemRef);
        const res = await fetch(`https://download.joon.com.np/${file.name}`, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            url,
          }),
        });

        const { shortURL } = await res.json();

        updateMetadata(itemRef, {
          customMetadata: {
            shortURL,
          },
        });

        setProgresses((progresses) =>
          progresses.map((progress) => {
            if (progress.filename === filename) {
              return {
                ...progress,
                percentage: "Done",
                url: "https://download.joon.com.np" + shortURL,
              };
            } else {
              return progress;
            }
          })
        );
      }
    );
  };

  return (
    <Container>
      <QRCodeDialog
        open={quCodeDialogOpen}
        setOpen={setQrCodeDialogOpen}
        link={fileLink}
      />
      <Card>
        <CardContent
          sx={{
            pb: "0 !important",
            px: "0 !important",
          }}
        >
          <Box
            sx={{
              px: 2,
            }}
          >
            <Typography variant="h5" component="h1" gutterBottom>
              Upload File
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                {...getRootProps()}
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "dashed",
                  borderRadius: "0.5rem",
                  padding: "1rem",
                  margin: ".5rem .5rem 0 .5rem",
                  color: "primary.main",
                  backgroundColor: "primary.light",
                  minHeight: "10rem",
                  textTransform: "none",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    backgroundColor: "primary.light2",
                  },
                }}
                component={Button}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography variant="h5" component="h2">
                    Drop the files here ...
                  </Typography>
                ) : (
                  <Typography variant="h5" component="h2" textAlign="center">
                    Drag and Drop files
                    <br />
                    or
                    <br />
                    Click to select files
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography
              variant="h5"
              component="h1"
              sx={{
                mt: 4,
              }}
            >
              Upload Progress
            </Typography>
          </Box>
          <List
            sx={{
              p: 0,
            }}
          >
            {progresses.length === 0 && (
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        my: 2,
                      }}
                      textAlign="center"
                      variant="body1"
                    >
                      No files are being uploaded
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {progresses.map((progress, index) => (
              <Box key={progress.filename}>
                {index !== 0 && <Divider component="li" />}

                <ListItem
                  sx={{
                    px: 0,
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      disabled={!progress.url}
                      onClick={() => {
                        setFileLink(progress.url);
                        setQrCodeDialogOpen(true);
                      }}
                    >
                      <QrCode2Icon
                        sx={{
                          fontSize: "1.8rem",
                        }}
                      />
                    </IconButton>
                  }
                >
                  <ListItemButton
                    component="a"
                    href={progress.url}
                    sx={{
                      py: 0,
                      cursor: progress.url ? "pointer" : "default",
                    }}
                    disableRipple={!progress.url}
                    disableTouchRipple={!progress.url}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            wordBreak: "break-word",
                          }}
                        >
                          {progress.filename.substring(37)}
                        </Typography>
                      }
                      secondary={
                        <>
                          <span
                            style={{
                              width: "100%",
                            }}
                          >
                            <LinearProgress
                              sx={{ my: 1 }}
                              variant={"determinate"}
                              color={
                                progress.percentage === "Done"
                                  ? "success"
                                  : progress.percentage === "Generating Link..."
                                  ? "purple"
                                  : "primary"
                              }
                              value={
                                progress.percentage === "Done" ||
                                progress.percentage === "Generating Link..."
                                  ? 100
                                  : parseInt(progress.percentage)
                              }
                            />
                          </span>
                          <span
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                            }}
                          >
                            <Typography variant="body2" component="span">
                              {progress.bytesTransferred} of{" "}
                              {progress.totalBytes}
                            </Typography>
                            <Typography variant="body2" component="span">
                              {progress.percentage}
                            </Typography>
                          </span>
                        </>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Box>
            ))}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UploadFile;
