import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const {
  REACT_APP_FIREBASE_apiKey: apiKey,
  REACT_APP_FIREBASE_authDomain: authDomain,
  REACT_APP_FIREBASE_projectId: projectId,
  REACT_APP_FIREBASE_storageBucket: storageBucket,
  REACT_APP_FIREBASE_messagingSenderId: messagingSenderId,
  REACT_APP_FIREBASE_appId: appId,
  REACT_APP_FIREBASE_measurementId: measurementId,
} = process.env;

const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
