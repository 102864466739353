import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";

export default function ElevateAppBar() {
  return (
    <>
      <CssBaseline />
      <AppBar
        sx={{
          background: "linear-gradient(90deg, #3498db, #8e44ad)",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{
              mx: "auto",
            }}
          >
            Joon Drive
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}
