import React from "react";
import { Box, Typography } from "@mui/material";

const Construction = () => {
  return (
    <Box
      sx={{
        p: 3,
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 3,
        }}
      >
        Joon Drive
      </Typography>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        This website is under construction.
      </Typography>
    </Box>
  );
};

export default Construction;
