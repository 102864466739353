import * as React from "react";
import Container from "@mui/material/Container";
import NavBar from "./components/NavBar";
import Uploads from "./pages/Uploads";
import NoteAndFileUpload from "./pages/NoteAndFileUpload";
import Construction from "./pages/Construction";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <NavBar />
      <Container
        sx={{
          backgroundColor: "primary.background",
          my: 4,
        }}
      >
        <Routes>
          <Route exact path="*" element={<Construction />} />
          <Route exact path="/3" element={<NoteAndFileUpload />} />
          <Route path="/test_uploads" element={<Uploads />} />
        </Routes>
      </Container>
    </Router>
  );
}
